import React from 'react';
import { Card, CardContent, Typography, CardActions, Button, Box } from '@mui/material';

export default function BoardGamesComingSoon() {

  return (
    <Box sx={{ 
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',  // viewport height
      backgroundSize: 'cover', // ensure the image covers the whole element
      backgroundPosition: 'center', // center the image
    }}>
      <Card sx={{ 
        maxWidth: 345, 
        textAlign: 'center', 
        backgroundColor: 'rgba(245, 245, 220, 0.8)', // a semi-transparent beige color
        color: '#21395A', 
        borderRadius: '15px', 
        boxShadow: '0px 10px 10px -3px rgba(0,0,0,0.1), 0px 4px 5px 0px rgba(0,0,0,0.07), 0px 5px 5px 0px rgba(0,0,0,0.06)' 
      }}>
        <CardContent>
          <Typography variant="h4" component="h2" sx={{ fontFamily: 'Baloo, sans-serif', fontWeight: 'bold', color: '#B32636' }}>
            Board Games
          </Typography>
          <Typography variant="h6" sx={{ fontFamily: 'Baloo, sans-serif', fontWeight: 'bold', color: '#21395A' }}>
            Coming Soon!
          </Typography>
        </CardContent>
        <CardActions>
          <Button variant="contained" sx={{ backgroundColor: '#B32636', color: '#FFFFFF', '&:hover': { backgroundColor: '#B32636' } }}>
            Stay Tuned!
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}
