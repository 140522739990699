import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';


const Stopwatch = ({ status, handleTime }) => {
  const [time, setTime] = useState(0);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    let interval = null;

    if (status !== 'completed') {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [status]);

  const seconds = `0${(time % 60)}`.slice(-2);
  const minutes = `0${Math.floor(time / 60) % 60}`.slice(-2);
  const hours = `0${Math.floor(time / 3600)}`.slice(-2);

  handleTime(status, `${hours}:${minutes}:${seconds}`) 

  return <Typography variant="body1"  sx={{ color: "white", fontWeight: 'bold'  }}>{`${hours}:${minutes}:${seconds}`}</Typography>;
};

export default Stopwatch;
