import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { AppBar, Toolbar, Box, Container, Typography, Button, CircularProgress, Grid } from '@mui/material';
import { supabase } from '../supabase';
import { useNavigate, useParams } from 'react-router-dom';

function QuizComponent() {
  const [questions, setQuestions] = useState([]);
  const [topic, setTopic] = useState([]);
  const [qIndex, setQIndex] = useState(1);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { slug } = useParams();

  useEffect(() => {
    fetchQuestions();
    fetchTopic();
  }, []);

  async function fetchQuestions() {
    let { data: quiz_questions, error } = await supabase
      .rpc('get_random_questions', { p_slug: slug });

    if (error) console.log("error", error);
    else {
      setQuestions(quiz_questions);
      setAnswers(new Array(quiz_questions.length).fill(null));
      setLoading(false);
    }
  }

  async function fetchTopic() {
    
        let { data: quiz_topics, error } = await supabase
            .from('quiz_topics')
            .select("*")
            .eq('slug', slug );

        if (error) console.log("error", error);
        else {
            setTopic(quiz_topics[0]);
            console.log("Topic: ", topic.name);
        }
    }

  const handleAnswerClick = (option) => {
    const updatedAnswers = [...answers];
    updatedAnswers[currentQuestion] = option;
    setAnswers(updatedAnswers);
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion((prevQuestion) => prevQuestion + 1);
      setQIndex(qIndex + 1);
    } else {
      sessionStorage.setItem('quiz_questions', JSON.stringify(questions));
      sessionStorage.setItem('quiz_answers', JSON.stringify(updatedAnswers));
      sessionStorage.setItem('slug', JSON.stringify(slug));
      sessionStorage.setItem('topic', JSON.stringify(topic));

      navigate('/results');
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  const options = ['option1', 'option2', 'option3', 'option4'];

  return (
    <div>
    <Helmet>
      <title>{`Tollugatti Quizzes - Quiz on ${topic.name}`}</title>
      <meta name="description" content={`Test your knowledge and dive into the world of ${topic.name}`} />
      <meta name="keywords" content="Ramayana quizzes, Puranas quizzes, Vedas quizzes, Upanishads quizzes, Bhagavad Gita quizzes, Dashavatara quizzes, Hindu scriptures quizzes" />
      <meta name="robots" content="index, follow" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="revisit-after" content="7 days" />
      <meta charset="UTF-8" />
      <meta property="og:title" content={`Engaging Quizzes on Hindu Scriptures : ${topic.name} - Tollugatti Quizzes`} />
      <meta property="og:description" content="Test your knowledge with captivating quizzes on Ramayana, Puranas, Vedas, Upanishads, Bhagavad Gita, Dashavatara, and more. Explore the depths of Hindu scriptures and unravel ancient wisdom. Start your quiz journey now!" />
      <meta property="og:image" content="[URL of image]" />
    </Helmet>        
    <AppBar position="static" style={{ backgroundColor: 'maroon' }}>
    <Toolbar style={{ display: 'flex', justifyContent: 'center' }}>
      <Typography variant="h6" style={{ fontFamily: 'Roboto', color: 'white' }}>
        {topic.name}
      </Typography>
    </Toolbar>
    </AppBar>
    <Container style={{ paddingTop: '20px' }}>
      <Typography variant="h4" component="h1" gutterBottom style={{ fontFamily: 'Roboto', display: 'flex', justifyContent: 'left'  }}>
        {qIndex}. {questions[currentQuestion].question}
      </Typography>
      <Grid container spacing={2}>
        {options.map((option, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Button variant="outlined" onClick={() => handleAnswerClick(questions[currentQuestion][option])} style={{ fontFamily: 'Roboto', width: '100%' }}>
              {questions[currentQuestion][option]}
            </Button>
          </Grid>
        ))}
      </Grid>
      <Box style={{ marginTop: '20px' }}>
        <Typography variant="h6" style={{ display: 'flex', justifyContent: 'left' }}>
          <RouterLink
            reloadDocument
            underline="hover"
            style={{ cursor: 'pointer', fontSize: '10pt' }}
            to={`/QuizShow/${slug}`}
          >
            Restart quiz on this topic
          </RouterLink>
        </Typography>
        <Typography variant="h6" style={{ display: 'flex', justifyContent: 'left' }}>
          <RouterLink
            underline="hover"
            style={{ cursor: 'pointer', fontSize: '10pt' }}
            to={`/Quiz`}
          >
            Take the quiz on a different topic
          </RouterLink>
        </Typography>
      </Box>
    </Container>
    </div>
  );
}

export default QuizComponent;
