import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

import { supabase } from '../supabase';
import useWindowSize from "../utils/useWindowSize";

const PuzzleComponent = ({ onGeometryChange, onEvent }) => {

  const [jigsawPuzzle, setJigsawPuzzle] = useState([]);

  let { slug } = useParams();

  const handleEvent = () => {
    onEvent("completed");
  };

  

  const fetchData = async () => {
    // Fetch topics
    const { data: puzzleData, error: puzzleError } = await supabase
      .from('puzzles')
      .select('*')
      .eq('enabled', true)
      .eq('slug', slug);

    if (puzzleError) {
      console.error(puzzleError)
      return
    }
  
    // Set the fetched data
    setJigsawPuzzle(puzzleData)
  }
      

  const windowSize = useWindowSize();

  useEffect(() => {
    const script = document.createElement("script");

    // script.src = "https://flbulgarelli.github.io/headbreaker/js/headbreaker.js";
    script.src = "https://gkdsquzkjjrpadlegrrg.supabase.co/storage/v1/object/public/sources/headbreaker.js?t=2023-06-01T14%3A22%3A56.157Z";
    script.async = true;

    document.body.appendChild(script);

    script.onload = () => {
      fetchData()  
    };

    return () => {
      document.body.removeChild(script);
    }
  }, []);


  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down('md'));

  let pieceSize = 133
  if (isScreenSmall) {
    pieceSize = 65
  }

  useEffect(() => {
    if (jigsawPuzzle.length > 0) {
      let xul = new Image();
      xul.src = jigsawPuzzle[0].image_url;
    // xul.src = 'https://picsum.photos/400/300'; // replace with your image path


      xul.onload = () => {
        const autogen = new window.headbreaker.Canvas('puzzle-canvas', {
          width: windowSize.width, height: windowSize.height+60,
          pieceSize: pieceSize, proximity: 20,
          borderFill: 10, strokeWidth: 1.5,
          lineSoftness: 0.18, image: xul,
          outline: new window.headbreaker.outline.Rounded(),
        });

        let nWidth = autogen.imageMetadata.content.naturalWidth;
        let nHeight = autogen.imageMetadata.content.naturalHeight;
        let hpCount = 8;
        let vpCount = 6;
        if (nWidth < nHeight) {
            hpCount = 5;
            vpCount = 6;
        } 
        
        onGeometryChange([hpCount, vpCount]);

        autogen.adjustImagesToPuzzleHeight();
        autogen.autogenerate({
          horizontalPiecesCount: hpCount,
          verticalPiecesCount: vpCount
        });
        // autogen.puzzle.pieces[4].translate(63, -56);

        autogen.shuffle(0.7);
        autogen.draw();

        autogen.onConnect((_piece, figure, _target, targetFigure) => {
      
          // paint borders on click
          // of conecting and conected figures
          figure.shape.stroke('yellow');
          targetFigure.shape.stroke('yellow');
          autogen.redraw();
      
          setTimeout(() => {
            // restore border colors
            // later
            figure.shape.stroke('black');
            targetFigure.shape.stroke('black');
            autogen.redraw();
          }, 200);
        });

        autogen.attachSolvedValidator();
        autogen.onValid(() => {
          handleEvent();
        })
      };
    }
  }, [jigsawPuzzle]);

  return (
    <div id="puzzle-canvas"></div>
  );
};

export default PuzzleComponent;
