import React, { useState, useEffect } from "react";
import { Grid, Card, CardActionArea, CardMedia, CardContent, Typography, Box } from "@mui/material";
import { supabase } from '../supabase'
import { Link  as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';


export default function JigsawPuzzles() {

    const [jigsawPuzzleData, setJigsawPuzzleData] = useState([]);

    const fetchData = async () => {
        // Fetch topics
        const { data: topicsData, error: topicsError } = await supabase
          .from('puzzle_topics')
          .select('*')
          .eq('enabled', true)
    
        console.log(topicsData)
    
        if (topicsError) {
          console.error(topicsError)
          return
        }
      
    
        // Map over the topics, fetching puzzles for each one
        const fetchedData = await Promise.all(topicsData.map(async (topic) => {
          // Fetch puzzles for the current topic
          const { data: puzzlesData, error: puzzlesError } = await supabase
          .from('puzzles')
          .select('*')
          .eq('topic_id', topic.id)
          .eq('enabled', true)
      
          if (puzzlesError) {
            console.error(puzzlesError)
            return
          }
      
          const puzzles = puzzlesData.map((puzzle) => ({
            title: puzzle.name,
            imageUrl: puzzle.image_url,
            thumbnailUrl: puzzle.thumbnail_url,
            slug: puzzle.slug 
          }))
    
          // Return the topic in the desired format
          return {
            topic: topic.name,
            puzzles: puzzles,
          }
        }))
        console.log(fetchedData)
        // Set the fetched data
        setJigsawPuzzleData(fetchedData)
      }
          
    useEffect(() => {
        fetchData()
      }, [])
    
        
  return (
    <>
    <Helmet>
      <title>Tollugatti Puzzles: Solve Online Jigsaw Puzzles on Dashavatara, Ramayana, Puranas, Devata Vahanas, and More</title>
      <meta name="description" content="Tollugatti Puzzles offers a vast collection of online jigsaw puzzles covering topics like Dashavatara, Ramayana, Puranas, Devata Vahanas, and more. Engage your mind, solve puzzles, and immerse yourself in captivating Hindu mythology. Start solving puzzles now!" />
      <meta name="keywords" content="Tollugatti puzzles, online jigsaw puzzles, Dashavatara puzzles, Ramayana puzzles, Puranas puzzles, Devata Vahanas puzzles, Hindu mythology puzzles" />
      <meta name="robots" content="index, follow" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta charset="UTF-8" />
      <meta property="og:title" content="Tollugatti Puzzles: Solve Online Jigsaw Puzzles on Dashavatara, Ramayana, Puranas, Devata Vahanas, and More" />
      <meta property="og:description" content="Tollugatti Puzzles offers a vast collection of online jigsaw puzzles covering topics like Dashavatara, Ramayana, Puranas, Devata Vahanas, and more. Engage your mind, solve puzzles, and immerse yourself in captivating Hindu mythology. Start solving puzzles now!" />
      <meta property="og:image" content="[URL of image]" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Tollugatti Puzzles: Solve Online Jigsaw Puzzles on Dashavatara, Ramayana, Puranas, Devata Vahanas, and More" />
      <meta name="twitter:description" content="Tollugatti Puzzles offers a vast collection of online jigsaw puzzles covering topics like Dashavatara, Ramayana, Puranas, Devata Vahanas, and more. Engage your mind, solve puzzles, and immerse yourself in captivating Hindu mythology. Start solving puzzles now!" />
      <meta name="twitter:image" content="[URL of image]" />
    </Helmet>
    <Box sx={{ p: 4 }}> 
    {jigsawPuzzleData.map((topicData) => (
      <Box key={topicData.topic} sx={{ pt: 2 }}>
            <Box sx={{ bgcolor: '#800000', width: '100%', px: 1, mr: 5 }}>
                <Typography variant="h5" gutterBottom sx={{ textAlign: 'left', fontFamily: 'Baloo, sans-serif', color: '#ffffff' }}>
                    {topicData.topic}
                </Typography>
            </Box>
        <Grid container spacing={2} sx={{ p: 1 }}>
          {topicData.puzzles.map((puzzle) => (
            <Grid item xs={6} sm={2} md={2} lg={1} key={puzzle.title}>
              <RouterLink to={`/PuzzleDet/${puzzle.slug}`}  className="noUnderline centerText"> 
              <Card>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    alt={puzzle.title}
                    width="100"
                    image={puzzle.thumbnailUrl}
                  />
                  <CardContent  sx={{ textDecoration: 'none', textAlign: 'center', bgcolor: '#cfe8fc' }}>
                    <Typography gutterBottom variant="body2" component="div"  sx={{ textDecoration: 'none', textAlign: 'center', fontFamily: 'Baloo, sans-serif', color: 'black' }}> 
                      {puzzle.title}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              </RouterLink>
            </Grid>
          ))}
        </Grid>
      </Box>
    ))}
  </Box>
  </>
  );
}
