import { Helmet } from 'react-helmet';

import { supabase } from '../supabase'
import { Link  as RouterLink } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Container, Grid, Card, CardActionArea, CardMedia, CardContent, Typography, useMediaQuery, useTheme } from '@mui/material';


function QuizList() {
  const [topics, setTopics] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  async function fetchTopics() {
    let { data: quiz_topics, error } = await supabase
      .from('quiz_topics')
      .select('*')
      .eq('enabled', true);
    console.log(quiz_topics);
    if (error) console.log("error", error);
    else setTopics(quiz_topics);
  }


  useEffect(() => {
    fetchTopics();
  }, []);

  return (
    <div>
    <Helmet>
      <title>{`Engaging Quizzes on Ramayana, Puranas, Vedas, Upanishads, Bhagavad Gita, and More - Tollugatti Quizzes`}</title>
      <meta name="description" content="Test your knowledge and dive into the enchanting world of Hindu scriptures with Tollugatti Quizzes. Explore captivating quizzes on Ramayana, Puranas, Vedas, Upanishads, Bhagavad Gita, Dashavatara, and more. Challenge yourself, unravel ancient wisdom, and discover the depths of your understanding. Start your quiz journey now!" />
      <meta name="keywords" content="Ramayana quizzes, Puranas quizzes, Vedas quizzes, Upanishads quizzes, Bhagavad Gita quizzes, Dashavatara quizzes, Hindu scriptures quizzes" />
      <meta name="robots" content="index, follow" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="revisit-after" content="7 days" />
      <meta charset="UTF-8" />
      <meta property="og:title" content="Engaging Quizzes on Hindu Scriptures - Tollugatti Quizzes" />
      <meta property="og:description" content="Test your knowledge with captivating quizzes on Ramayana, Puranas, Vedas, Upanishads, Bhagavad Gita, Dashavatara, and more. Explore the depths of Hindu scriptures and unravel ancient wisdom. Start your quiz journey now!" />
      <meta property="og:image" content="[URL of image]" />
    </Helmet>
    <AppBar position="static" style={{ backgroundColor: 'maroon' }}>
    <Toolbar style={{ display: 'flex', justifyContent: 'center' }}>
      <Typography variant="h6" style={{ fontFamily: 'Roboto', color: 'white' }}>
        Quizzes
      </Typography>
    </Toolbar>
    </AppBar>
    <Container maxWidth="lg" style={{ padding: '20px' }}>
      <Grid container spacing={isMobile ? 2 : 4}>
        {topics.map((topic) => (
          <Grid item xs={12} sm={6} md={4} key={topic.id}>
            <Card>
              <CardActionArea href={`/QuizShow/${topic.slug}`}>
                <CardMedia
                  component="img"
                  height="200"
                  image={topic.image_url}
                  alt={topic.name}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {topic.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {topic.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
    </div>
  );
}

export default QuizList;
