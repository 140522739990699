import React, { useState } from "react";
import { RWebShare } from "react-web-share";
import { FaShareAlt } from "react-icons/fa";

const fullscreenEnabled = () =>
  document.fullscreenEnabled || document.webkitFullscreenEnabled || document.mozFullScreenEnabled || document.msFullscreenEnabled;

const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) { /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE/Edge */
      document.msExitFullscreen();
    }
  };
  
  const isFullscreen = () => 
    document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;
  
const handleFullscreen = () => {
        if (!fullscreenEnabled()) return;
    
        if (isFullscreen()) {
          exitFullscreen();
        }
      };

const Share = ({sharetext, shareurl, sharetitle}) => {

    return (
        <div>
        <RWebShare
            data={{
            text: sharetext,
            url: shareurl,
            title: sharetitle,
            }}
            onClick={() => console.log("shared successfully!")}
        >
            <FaShareAlt color="orange" />
        </RWebShare>
        </div>
    );
};

export default Share;