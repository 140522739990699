import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardMedia, Typography, Box, Button } from "@mui/material";
import { supabase } from '../supabase';
import useWindowSize from "../utils/useWindowSize";
import { Link  as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';


const PuzzleDetail = () => {
  const [jigsawPuzzle, setJigsawPuzzle] = useState([]);
  let { slug } = useParams();

  const fetchData = async () => {
    const { data: puzzleData, error: puzzleError } = await supabase
      .from('puzzles')
      .select('*')
      .eq('enabled', true)
      .eq('slug', slug);

    if (puzzleError) {
      console.error(puzzleError)
      return
    }

    setJigsawPuzzle(puzzleData[0])
  }
  
  useEffect(() => {
    fetchData();
  }, []);

  const { width } = useWindowSize();
  const showDescription = width > 768; // Show description for screens larger than 768px

  return (
    <>
    <Card sx={{ maxWidth: 600, boxShadow: 3, mx: 'auto' }}>
      <Box sx={{ bgcolor: 'maroon', color: 'white', fontFamily: 'Baloo, sans-serif', fontWeight: 'bold' }}>
        <Typography variant="h5" gutterBottom sx={{ textAlign: 'left', ml: 2 }}>
          {jigsawPuzzle.name}
        </Typography>
      </Box>
      <CardMedia
        component="img"
        alt={jigsawPuzzle.name}
        image={jigsawPuzzle.image_url}
      />
      {showDescription && (
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {jigsawPuzzle.description}
          </Typography>
        </CardContent>
      )}
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
      <RouterLink to={`/puzzle/${jigsawPuzzle.slug}`}  className="noUnderline centerText"> 
        <Button variant="contained" sx={{ bgcolor: 'maroon', color: 'white', fontFamily: 'Baloo, sans-serif' }}>
          Solve as a Jigsaw Puzzle
        </Button>
      </RouterLink>
      <Box sx={{ flexGrow: 0.05 }} />
      <RouterLink to="https://rollthedice.in/collections/trita-jigsaw-puzzles"  className="noUnderline centerText"> 
        <Button variant="contained" sx={{ bgcolor: 'maroon', color: 'white', fontFamily: 'Baloo, sans-serif' }}>
          Buy a hard copy
        </Button>
      </RouterLink>
      </Box>
    </Card>
    <Helmet>
      <title>{`Jigsaw Puzzle - ${jigsawPuzzle.slug}`}.</title>
      <meta name="description" content="tollugatti is a platform for kids and adults to have fun" />
    </Helmet>
    </>
  );
};

export default PuzzleDetail;
