import React, { useState } from "react";
import { AppBar, Toolbar, IconButton, Typography, Button, Drawer, List, ListItem, ListItemText, ListItemIcon, Divider } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import PuzzleIcon from "@mui/icons-material/Extension"; 
import QuizIcon from "@mui/icons-material/Quiz"; 
import BoardGameIcon from "@mui/icons-material/GridView"; 
import { makeStyles } from "@mui/styles";  
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles({
  list: {
    backgroundColor: "#1976d2",
    width: "100%",
  },
  link: {
    textDecoration: "none",
    color: "white",
    fontWeight: "bold",
  },
  divider: {
    backgroundColor: "white",
  },
});

function Navbar() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const classes = useStyles();
  
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const items = [
    { text: "Puzzles", icon: <PuzzleIcon />, link: "/puzzles" },
    { text: "Quiz", icon: <QuizIcon />, link: "/quiz" },
    { text: "Board Games", icon: <BoardGameIcon />, link: "/boardgames" },
  ];

  const list = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      style={{ width: 250, backgroundColor: 'lightblue' }} 
    >
      <List>
        {items.map((item, index) => (
          <div key={item.text} className={classes.list}>
            <ListItem button component={RouterLink} to={`${item.link}`} sx={{ textDecoration: 'none', color: 'white', fontWeight: 'bolder' }}>
                <ListItemIcon sx={{ color: 'white' }}>
                 {item.icon}
                </ListItemIcon>
            <ListItemText primary={item.text} />
            </ListItem>
            {index < items.length - 1 && <Divider className={classes.divider} />} 
          </div>
        ))}
      </List>
    </div>
  );

  return (
    <AppBar position="static" >
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h6">
          tollugatti
        </Typography>
        <Drawer anchor={"left"} open={drawerOpen} onClose={toggleDrawer(false)}>
          {list()}
        </Drawer>
        <div style={{ marginLeft: 'auto' }}>
        <RouterLink to="https://rollthedice.in/" target="_blank">
          <IconButton edge="end" color="inherit" aria-label="logo">
            <img src="https://gkdsquzkjjrpadlegrrg.supabase.co/storage/v1/object/public/logos/rollthedice.png?t=2023-06-01T05%3A13%3A29.336Z" alt="logo" style={{ maxWidth: '150px' }} />
          </IconButton>
        </RouterLink>
      </div>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;