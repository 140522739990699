import React from 'react';

const TermsOfService = () => {
  return (
    <div className="terms-of-service">
      <h1>Terms of Service</h1>

      <h2>Introduction</h2>
      <p>Welcome to Tollugatti! Our platform offers a range of digital games, cultural knowledge activities, and resources focused on Indian culture. These Terms of Service govern your access to and use of Tollugatti’s services and platform.</p>

      <h2>Acceptance of Terms</h2>
      <p>By accessing or using our platform, you agree to be bound by these Terms of Service and our Privacy Policy. If you do not agree to these terms, please do not use our services.</p>

      <h2>Service Description</h2>
      <p>Tollugatti provides a digital platform for playing Indian board games and engaging in activities that promote cultural knowledge. Our services are accessible via our website and potentially mobile applications.</p>

      <h2>User Accounts</h2>
      <ul>
        <li><strong>Account Creation</strong>: To access certain features of the platform, you must create an account using Google authentication.</li>
        <li><strong>Responsibility</strong>: You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</li>
        <li><strong>Accuracy of Information</strong>: You agree to provide accurate, current, and complete information about yourself as prompted by our registration forms.</li>
      </ul>

      <h2>User Conduct</h2>
      <p>You agree to use the platform only for lawful purposes and in a way that does not infringe the rights of, restrict or inhibit anyone else’s use and enjoyment of the platform.</p>
      <p>You are prohibited from using the platform to engage in any form of harassment, offensive behavior, or any activity that violates the rights of others or is harmful to the experience of other users.</p>

      <h2>Intellectual Property Rights</h2>
      <p>The content on Tollugatti, including games, text, graphics, images, and other material, is protected by intellectual property rights and is the exclusive property of Tollugatti or its licensors. You may not reproduce, distribute, modify, or use the content without prior written permission.</p>

      <h2>Termination</h2>
      <p>We may terminate or suspend your account and access to our services immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms of Service.</p>

      <h2>Changes to Terms</h2>
      <p>We reserve the right to modify these terms at any time. Your continued use of the platform after such changes have been made constitutes your acceptance of the new Terms of Service.</p>

      <h2>Disclaimer of Warranties</h2>
      <p>Our platform is provided on an "as is" and "as available" basis. Tollugatti makes no representations or warranties of any kind, express or implied, as to the operation of our services or the information, content, or materials included on our platform.</p>

      <h2>Limitation of Liability</h2>
      <p>Tollugatti will not be liable for any damages of any kind arising from the use of our platform, including, but not limited to direct, indirect, incidental, punitive, and consequential damages.</p>

      <h2>Governing Law</h2>
      <p>These Terms of Service shall be governed by and construed in accordance with the laws of the jurisdiction in which our company is registered, without regard to its conflict of law provisions.</p>

      <h2>Contact Us</h2>
      <p>If you have any questions about these Terms of Service, please contact us at dev@tollugatti.in </p>
    </div>
  );
}

export default TermsOfService;
