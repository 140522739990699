import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>

      <h2>Introduction</h2>
      <p>Welcome to Tollugatti! We are dedicated to providing a fun and educational experience through our digital platform that focuses on Indian board games, cultural knowledge, and various activities. This privacy policy outlines how we handle your personal information.</p>

      <h2>Information Collection</h2>
      <p>We only collect the minimum amount of personal information necessary to enhance your experience on our platform:</p>
      <ul>
        <li><strong>Email and/or Phone Number</strong>: We collect your email address and/or phone number only when you voluntarily provide it to us. This information is used to send you updates and information about our activities and services.</li>
        <li><strong>Authentication</strong>: We use Google Authentication to securely manage your login to our platform. Please refer to Google's privacy policy for details on how they handle your information.</li>
      </ul>

      <h2>Use of Information</h2>
      <p>The personal information you provide is used exclusively to enhance your experience and to keep you informed about what’s new on our platform. We do not use your personal information for any other purposes, nor do we share it with third parties for their promotional purposes.</p>

      <h2>Data Security</h2>
      <p>We are committed to ensuring that your information is secure. We use industry-standard security measures to prevent unauthorized access, disclosure, alteration, or destruction of your personal information.</p>

      <h2>Changes to This Policy</h2>
      <p>We may update our privacy policy from time to time. Any changes will be posted on this page, and we encourage you to review our policy periodically to stay informed of how we are protecting your information.</p>

      <h2>Contact Us</h2>
      <p>If you have any questions about this privacy policy, or if you need further information regarding your personal data, please contact us at dev@tollugatti.in .</p>
    </div>
  );
}

export default PrivacyPolicy;
