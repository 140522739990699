import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Link  as RouterLink } from 'react-router-dom';
import { css, keyframes } from '@emotion/react';

import { supabase } from '../supabase'
import { Card, IconButton, useMediaQuery, Toolbar, Typography, Box, Button } from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import DeviceOrientation, { Orientation } from 'react-screen-orientation'

import { useTheme } from "@mui/material/styles";
import PuzzleComponent from "./PuzzleComponent";  // Path to the new PuzzleComponent
import useWindowSize from "../utils/useWindowSize";
import Stopwatch from "../utils/stopWatch";
import Share from "./Share";
import s from "react-stopwatch";


const fullscreenEnabled = () =>
  document.fullscreenEnabled || document.webkitFullscreenEnabled || document.mozFullScreenEnabled || document.msFullscreenEnabled;

const requestFullscreen = (element) => {
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.mozRequestFullScreen) { /* Firefox */
    element.mozRequestFullScreen();
  } else if (element.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
    element.webkitRequestFullscreen();
  } else if (element.msRequestFullscreen) { /* IE/Edge */
    element.msRequestFullscreen();
  }
};

const exitFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) { /* Firefox */
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) { /* IE/Edge */
    document.msExitFullscreen();
  }
};

const isFullscreen = () => 
  document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;


export default function Puzzle() {

  const [status, setStatus] = useState("in-progress");
  const [timeTaken, setTimeTaken] = useState('');
  const [puzzleGeometry, setPuzzleGeometry] = useState([6, 5]);
  const [sharetext, setSharetext] = useState('');

  const handleGeometryChange = (newGeometry) => {
    setPuzzleGeometry(newGeometry);
  };

  const cardRef = React.useRef(null);
  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const handleFullscreen = () => {
    if (!fullscreenEnabled()) return;

    if (!isFullscreen()) {
      requestFullscreen(cardRef.current);
    } else {
      exitFullscreen();
    }
  };

  const windowSize = useWindowSize();

  const [puzzle, setPuzzle] = useState([]);
  const [randomPuzzle, setRandomPuzzle] = useState([]);

  
  let { slug } = useParams();

  const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
  `;

  const blinkingStyle = css`
    animation: ${blink} 1s linear infinite;
  `;

  const handleTime = (status, e) => {
    if (status === "completed") {
        setTimeTaken(e);
        setSharetext("I solved the puzzle "+ puzzle.name +" in " + e + " time on TolluGatti. How much time will you take to solve it?");
    } else {
        setSharetext("I am currently solving the puzzle "+ puzzle.name + " on TolluGatti. Do try it out for yourself!");
    }
   };


  const fetchData = async () => {
    // Fetch topics
    const { data: puzzleData, error: puzzleError } = await supabase
      .from('puzzles')
      .select('*')
      .eq('enabled', true)
      .eq('slug', slug);

    console.log("Puzzle:", puzzleData)

    if (puzzleError) {
      console.error(puzzleError)
      return
    }
  
    // Set the fetched data
    setPuzzle(puzzleData[0])
  }

  const fetchRandom = async () => {

    let exclude_slug = slug
    let { data, error } = await supabase
            .rpc('get_random_puzzle', {exclude_slug})
  
    if (error) { 
        console.error(error)
        return
    }

    setRandomPuzzle(data[0])
  }

  useEffect(() => {
    fetchData();
    fetchRandom();
  }, []);



  if (isScreenSmall) {

    return (
      
      <DeviceOrientation lockOrientation={'landscape'}>
      {/* Will only be in DOM in landscape */}
      <Orientation orientation='landscape' alwaysRender={false}>
        <div>
          <p>Try refreshing the browser while in the landscape mode.</p>
        </div>
      </Orientation>
      {/* Will stay in DOM, but is only visible in portrait */}
      <Orientation orientation='portrait'>
        <div>
          <p>For the best experience, <br />
          please rotate your device <br />
          and enable Full Screen by clicking the icon</p>
        </div>
      </Orientation>
      </DeviceOrientation>

    )

  } else {

  return (
    <>
    <Helmet>
      <title>{`Tollugatti Puzzles: Solve Online Jigsaw Puzzles on ${puzzle.name}`}</title>
      <meta name="description" content={`Tollugatti Puzzles: Solve Online Jigsaw Puzzles on Dashavatara, Ramayana, Puranas, Devata Vahanas, and More - ${puzzle.name}`} />
      <meta name="keywords" content="Tollugatti puzzles, online jigsaw puzzles, Dashavatara puzzles, Ramayana puzzles, Puranas puzzles, Devata Vahanas puzzles, Hindu mythology puzzles" />
      <meta name="robots" content="index, follow" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta charset="UTF-8" />
      <meta property="og:title" content="Tollugatti Puzzles: Solve Online Jigsaw Puzzles on Dashavatara, Ramayana, Puranas, Devata Vahanas, and More" />
      <meta property="og:description" content="Tollugatti Puzzles offers a vast collection of online jigsaw puzzles covering topics like Dashavatara, Ramayana, Puranas, Devata Vahanas, and more. Engage your mind, solve puzzles, and immerse yourself in captivating Hindu mythology. Start solving puzzles now!" />
      <meta property="og:image" content="[URL of image]" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Tollugatti Puzzles: Solve Online Jigsaw Puzzles on Dashavatara, Ramayana, Puranas, Devata Vahanas, and More" />
      <meta name="twitter:description" content="Tollugatti Puzzles offers a vast collection of online jigsaw puzzles covering topics like Dashavatara, Ramayana, Puranas, Devata Vahanas, and more. Engage your mind, solve puzzles, and immerse yourself in captivating Hindu mythology. Start solving puzzles now!" />
      <meta name="twitter:image" content="[URL of image]" />
    </Helmet>

    <Card ref={cardRef} style={{ position: 'absolute', height: windowSize.height, width: windowSize.width, margin: 'auto' }}>
      <PuzzleComponent onGeometryChange={handleGeometryChange}  onEvent={setStatus} />
      <Toolbar variant="dense" sx={{ zIndex: 'tooltip' }} style={{ position: 'absolute', bottom: 0, width: '100%', background: isFullscreen() ? 'rgba(0, 0, 0, 0.5)' : '#1976d2'}}>
        <Typography variant="body1" sx={{ color: "white", lineHeight: '1.0' }}>
        { status === "in-progress" && puzzle.name }
        { status === "completed" &&
            <RouterLink to={`/PuzzleDet/${randomPuzzle.slug}`}  className="noUnderline centerText"> 
              <Button variant="contained" sx={{ bgcolor: 'maroon', color: 'white', fontFamily: 'Baloo, sans-serif' }}>
               Congratulations!!! Solve another puzzle
               </Button>
            </RouterLink>
        }

        </Typography>
        
        <Box sx={{ flexGrow: 0.3}} />
        {status === "completed" && <EmojiEventsIcon sx={{ color: "gold" }} />}
        {status === "in-progress" && <AccessAlarmIcon sx={{ color: "orange" }} css={blinkingStyle} />}
        <Box sx={{ flexGrow: 0.005 }} />
        <Stopwatch  status={status} handleTime={handleTime} />
        <Box sx={{ flexGrow: 0.1 }} />
        <Typography variant="body1" sx={{ color: "white", lineHeight: '1.0', fontFamily: 'Baloo, sans-serif' }}>
        Blocks - Across {`${puzzleGeometry[0]}`} X Down {`${puzzleGeometry[1]}`} 
        </Typography>
        <Box sx={{ flexGrow: 0.05 }} />
        <Share sharetext={sharetext} shareurl={`https://tollugatti.com/PuzzleDet/${puzzle.slug}`} sharetitle={`${puzzle.name}`} />
        <Box sx={{ flexGrow: 0.1 }} />
        <IconButton onClick={handleFullscreen}>
          {isFullscreen() ? <FullscreenExitIcon sx={{ color: "white" }} /> : <FullscreenIcon sx={{ color: "white" }} />}
        </IconButton>
      </Toolbar>
    </Card>
    </>

  );
  }
}
