import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';


import './App.css';
import Navbar from "./components/Navbar";
import JigsawPuzzles from "./components/JigsawPuzzles";
import Puzzle from "./components/PuzzlePage";
import PuzzleDetail from "./components/PuzzleDetail";
import QuizList from "./components/QuizList";
import BoardGamesComingSoon from "./components/BoardGames";
import QuizComponent from "./components/QuizComponent";
import QuizResults from "./components/QuizResults";
import PrivacyPolicy from "./components/Privacy";
import TermsOfService from "./components/Terms";

const App = () => {


  return (
    <>
    <Helmet>
      <title>tollugatti - online jigsaw puzzles, quizzes, board games.</title>
      <meta name="description" content="tollugatti is a platform for kids and adults to have fun" />
    </Helmet>
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
            <Route path="/boardgames" element={<BoardGamesComingSoon/>} />
            <Route path="/quiz" element={<QuizList/>} />
            <Route path="/QuizShow/:slug" element={<QuizComponent/>} />
            <Route path="/results" element={<QuizResults/>} />
            <Route path="/puzzle/:slug" element={<Puzzle />} />
            <Route path="/puzzles" element={<JigsawPuzzles/>} />
            <Route path="/PuzzleDet/:slug" element={<PuzzleDetail/>} />
            <Route path="/privacy" element={<PrivacyPolicy/>} />
            <Route path="/terms" element={<TermsOfService/>} />
            <Route path="/" element={<JigsawPuzzles/>} />
          </Routes>
      </div>
    </Router>
    </>

  );
  
};

export default App;
