import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Container, Grid, Button, LinearProgress, Box, Divider } from '@mui/material';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import { useNavigate, useParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';


function QuizResults() {
  const [questions, setQuestions] = useState(JSON.parse(sessionStorage.getItem('quiz_questions')));
  const userAnswers = JSON.parse(sessionStorage.getItem('quiz_answers'));
  const slug = JSON.parse(sessionStorage.getItem('slug'));
  const topic = JSON.parse(sessionStorage.getItem('topic'));

  const navigate = useNavigate();

  const correctAnswers = questions.map(question => question.correct);
  const score = userAnswers.reduce((score, answer, index) => {
    return answer === correctAnswers[index] ? score + 1 : score;
  }, 0);
  
  const scorePercentage = (score / questions.length) * 100;

  return (
    <div>
    <Helmet>
      <title>{`Tollugatti Quizzes - Results for Quiz on ${topic.name}`}</title>
      <meta name="description" content={`Test your knowledge and dive into the world of ${topic.name}`} />
      <meta name="keywords" content="Ramayana quizzes, Puranas quizzes, Vedas quizzes, Upanishads quizzes, Bhagavad Gita quizzes, Dashavatara quizzes, Hindu scriptures quizzes" />
      <meta name="robots" content="index, follow" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="revisit-after" content="7 days" />
      <meta charset="UTF-8" />
      <meta property="og:title" content={`Engaging Quizzes on Hindu Scriptures : ${topic.name} - Tollugatti Quizzes`} />
      <meta property="og:description" content="Test your knowledge with captivating quizzes on Ramayana, Puranas, Vedas, Upanishads, Bhagavad Gita, Dashavatara, and more. Explore the depths of Hindu scriptures and unravel ancient wisdom. Start your quiz journey now!" />
      <meta property="og:image" content="[URL of image]" />
    </Helmet>             
    <AppBar position="static" style={{ backgroundColor: 'maroon' }}>
    <Toolbar style={{ display: 'flex', justifyContent: 'center' }}>
      <Typography variant="h6" style={{ fontFamily: 'Roboto', color: 'white' }}>
        {topic.name}
      </Typography>
    </Toolbar>
    </AppBar>
    <Container style={{ padding: '10px' }}>
        <Typography variant="h6" component="h3" style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>Your Score: {score}/{questions.length}</Typography>
        <LinearProgress variant="determinate" value={scorePercentage} />

      {questions.map((question, index) => (
        <>
        <Box key={index} style={{ margin: '10px 0', padding: '10px', backgroundColor: '' }}>
          <Typography variant="h6" component="h2" style={{ display: 'flex', justifyContent: 'left', marginBottom: '5px' }}>{index+1}. {question.question}</Typography>
          <Grid container spacing={2}>
            {['option1', 'option2', 'option3', 'option4'].map((option, tindex) => {
                let backgroundColor = '#e0ffff';
                let borderColor = '';
                let borderWidth = '';
                let color = 'black';

                if (question[option] === question.correct) {
                    borderColor = 'green';
                    borderWidth = '3px';

                    if (question[option] === userAnswers[index]) {
                        backgroundColor = 'green';
                        color = 'white';
                    } 
                } else if (question[option] === userAnswers[index]) {
                    backgroundColor = 'red';
                    color = 'white';

                }
            return (
                <Grid item xs={12} md={6} key={index}>
                    <Button 
                        variant="outlined" 
                        style={{ 
                            fontFamily: 'Roboto', 
                            width: '100%', 
                            backgroundColor: backgroundColor,
                            borderColor: borderColor,
                            borderWidth: borderWidth,
                            color: color
                        }}
                    >
                    {question[option]}
                    </Button>
                </Grid>
                
                );
            })}
          </Grid>
          <Box style={{ margin: '20px 0', padding: '10px', backgroundColor: '#187bcd', color: 'white', display: 'flex', justifyContent: 'flex-start',  width: '100%' }}>
            <TipsAndUpdatesOutlinedIcon style={{ marginRight: '10px' }} />
            <Box style={{ textAlign: 'left', width: '100%' }}>
            <Typography variant="body">
                {question.context}
            </Typography>
            </Box>
            </Box>
        </Box>


        <Divider  style={{ backgroundColor: 'black', height: '3px' }}  />
        </>
      ))}
      <Typography variant="h5" component="h3" style={{ margin: '20px 0' }}>Your Score: {score}/{questions.length}</Typography>
      <LinearProgress variant="determinate" value={scorePercentage} />
      <Button variant="contained" color="primary" onClick={() => navigate(`/QuizShow/${slug}`)} style={{ margin: '20px 0' }}>
        Retry Quiz
      </Button>
      <Typography variant="h6" style={{ display: 'flex', justifyContent: 'center' }}>
          <RouterLink
            underline="hover"
            style={{ cursor: 'pointer', fontSize: '10pt' }}
            to={`/Quiz`}
          >
            Take the quiz on a different topic
          </RouterLink>
        </Typography>
    </Container>
    </div>
  );
}

export default QuizResults;
